import {CSSProperties, ReactNode} from 'react';
import {Link as RouterLink} from 'react-router-dom';

const Link = ({ children, style, className, disabled, href, ariaLabel, newTab }: ClickableProps) => {
  return (
    <RouterLink
      tabIndex={disabled ? undefined : 0}
      className={`clickable ${className} basic-link`}
      style={style}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      to={href}
      target={newTab ? '_blank' : ''}
      rel={newTab ? "noreferrer noopener" : ''}
    >
      {children}
    </RouterLink>
  );
};

interface ClickableProps {
  children: ReactNode,
  ariaLabel: string,
  href: string,
  disabled?: boolean,
  newTab?: boolean,
  style?: CSSProperties,
  className?: string,
  role?: string
}

export default Link;
