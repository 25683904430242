import {CSSProperties, ReactNode} from 'react';
import { Tooltip } from '@mui/material';
import InteractiveLinkElement from "./InteractiveLinkElement.tsx";
import {useIsHiddenForEditComponent} from "../../../interface/overlayStore.ts";

const TooltipPrimaryInteractiveIconLink = ({ children, href, tooltip, circle, length, noBorder, disabled, forSettlementEdit, style, selected, newTab }: TooltipPrimaryInteractiveIconProps) => {
  const baseHidden = useIsHiddenForEditComponent();
  const hiddenForEdit = forSettlementEdit && baseHidden;
  return (
    <InteractiveLinkElement style={style} href={href} circle={circle} noBorder={noBorder} ariaLabel={tooltip} disabled={disabled || hiddenForEdit} selected={selected} newTab={newTab}>
      <Tooltip title={tooltip} arrow enterDelay={300}>
        <div style={{ width: `${length || 15}px`, height: `${length || 15}px`, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' }}>
          { children }
        </div>
      </Tooltip>
    </InteractiveLinkElement>
  );
};

interface TooltipPrimaryInteractiveIconProps {
  children: ReactNode,
  href: string,
  tooltip: string,
  circle?: boolean,
  noBorder?: boolean,
  disabled?: boolean,
  forSettlementEdit?: boolean,
  length?: number,
  style?: CSSProperties,
  selected?: boolean,
  newTab?: boolean,
}

export default TooltipPrimaryInteractiveIconLink;
