import CenterRow from "../row/CenterRow.tsx";
import {MdMail} from "react-icons/md";
import {FaDiscord, FaRedditAlien} from "react-icons/fa";
import Button from "../interaction/Button.tsx";
import {useRenderOptionsStore} from "../../render/renderOptionsStore.ts";
import TooltipPrimaryInteractiveIconLink from "../interaction/link/TooltipPrimaryInteractiveIconLink.tsx";

interface ErrorWithMessage {
  message?: unknown
}

function formatError(error: unknown): string {
  if (typeof error === 'object' && error) {
    const messageMaybe = (error as ErrorWithMessage).message
    if (messageMaybe && typeof messageMaybe === 'string') {
      return messageMaybe
    }
    return error.toString()
  }
  return 'Unknown error';
}

interface SentryError {
  error: unknown;
  componentStack: string;
  eventId: string;

  resetError(): void;
}


export function Fallback({error, duringRender}: { error: SentryError, duringRender?: boolean }) {
  const formattedError = formatError(error.error)

  return (
    <div style={{padding: '20px'}}>
      {!duringRender && (
        <CenterRow>
          <p>Whoops! Something went wrong. You can report bugs in the discord, subreddit, or by emailing me. Please
            include the error message below.</p>
        </CenterRow>
      )}
      {duringRender && (
        <>
        <CenterRow>
          <p>
            Whoops! Something went wrong. It looks like this error occurred while rendering the map - you can try using
            the SVG renderer to resolve this (this control is found in the render settings) - or try changing the other
            render settings. You can report bugs in the discord, subreddit, or by emailing me. Please include the error
            message below.
          </p>
        </CenterRow>
        <CenterRow>
          <Button text="Reload with SVG renderer" onClick={() => useRenderOptionsStore.getState().enableSvgRenderer()} variant="secondary"/>
        </CenterRow>
        </>
      )}
      <CenterRow>
        <p style={{fontFamily: 'courier'}}>Error: {formattedError} (Event id: {error.eventId})</p>
        <p style={{fontFamily: 'courier'}}>
          Stack trace:
          {error.componentStack}
        </p>
      </CenterRow>
      <CenterRow style={{gap: '10px', marginBottom: '20px'}}>
        <TooltipPrimaryInteractiveIconLink href="mailto: fantasytowngenerator@gmail.com"
                                tooltip="Email fantasytowngenerator@gmail.com" length={30} circle newTab>
          <MdMail size={22}/>
        </TooltipPrimaryInteractiveIconLink>
        <TooltipPrimaryInteractiveIconLink href="https://www.reddit.com/r/fantasytowngenerator/" tooltip="Subreddit" length={30}
                                circle newTab>
          <FaRedditAlien size={22}/>
        </TooltipPrimaryInteractiveIconLink>
        <TooltipPrimaryInteractiveIconLink href="https://discord.gg/RrUNpebjGD" tooltip="Discord" length={30} circle newTab>
          <FaDiscord size={22}/>
        </TooltipPrimaryInteractiveIconLink>
      </CenterRow>
    </div>
  );
}
