import {CSSProperties, MouseEventHandler, ReactNode, useMemo} from 'react';
import { Button as MiButton } from '@mui/material';

type Variant = 'contained'|'outlined';
type Color = 'primary'|'secondary';

const Button = ({ onClick, onFocus, href, onBlur, text, customContent, children, size, variant, type, endIcon, style, className, disabled, startIcon, role }: ButtonProps) => {
  const [actualVariant, color]: [Variant, Color] = useMemo(() => {
    if (variant === 'primary') {
      return ['contained', 'primary'];
    }
    if (variant === 'secondary') {
      return ['outlined', 'primary'];
    }
    return ['outlined', 'secondary'];
  }, [variant]);

  const finalStyle: CSSProperties = useMemo(() => {
    if (size === 'xs') {
      return {
        padding: '0 2px',
        fontSize: '0.7rem',
        fontWeight: 'var(--body-bold-font-weight)',
        minWidth: '3rem',
        textTransform: 'none',
        ...style
      };
    }
    if (size === 'small') {
      return {
        padding: '4px 8px',
        textTransform: 'none',
        height: 'fit-content',
        ...style
      };
    }
    return { textTransform: 'none', height: 'fit-content', ...style };
  }, [style, size]);

  return (
    <MiButton
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      className={className}
      endIcon={endIcon}
      disabled={disabled}
      startIcon={startIcon}
      href={href}
      role={role}
      variant={actualVariant}
      color={color}
      type={type}
      style={finalStyle}
    >
      {text}
      {customContent}
      {children}
    </MiButton>
  );
};

interface ButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>,
  size?: 'xs'|'small',
  variant?: 'primary'|'secondary',
  type?: "submit" | "reset" | "button",
  onFocus?: () => void,
  onBlur?: () => void,
  endIcon?: ReactNode,
  startIcon?: ReactNode,
  text?: string,
  className?: string,
  href?: string,
  role?: string,
  style?: CSSProperties,
  customContent?: string|ReactNode,
  children?: ReactNode,
  disabled?: boolean,
}

export default Button;
