import '../../Common.css';
import InteractiveElement from '../InteractiveElement.tsx';
import {CSSProperties, ReactNode} from "react";
import Link from "./Link.tsx";

const InteractiveLinkElement = ({ children, href, style, clickableStyle, circle, noBorder, className, childClassName, ariaLabel, disabled, selected, newTab }: ClickableInteractiveElementProps) => (
  <InteractiveElement {...{ style, circle, noBorder, className, disabled, selected }}>
    <Link href={href} style={clickableStyle} className={`full-width-clickable ${childClassName || ''}`} ariaLabel={ariaLabel} disabled={disabled} newTab={newTab}>
      {children}
    </Link>
  </InteractiveElement>
);

interface ClickableInteractiveElementProps {
  children: ReactNode,
  href: string,
  circle?: boolean,
  noBorder?: boolean,
  disabled?: boolean,
  style?: CSSProperties
  className?: string,
  childClassName?: string,
  ariaLabel: string,
  clickableStyle?: CSSProperties,
  selected?: boolean,
  newTab?: boolean,
}

export default InteractiveLinkElement;
